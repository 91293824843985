import FooterTop from "../utils/FooterTop";
import { Link } from "react-router-dom";
const Footer = ()=>{
    return (
      <footer
        className="footerContainer container"
        style={{ marginTop: "-1rem" }}>
        <div className="footerTop">
          <div>
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Medios de pago</div>
                <h3 className="footerCardDetails">
                  Todas las tarjetas, PSE, efectivo,
                  <br />
                  contra entrega, Addi y Sistecrédito
                </h3>
              </div>
              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100px"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve">
                <image
                  id="image1"
                  width="100"
                  height="100"
                  href="./card.webp"></image>
              </svg>
            </div>
          </div>
          <div className="payment">
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Entregas veloces</div>
                <h3 className="footerCardDetails">
                  En cuestión de horas entregamos <br />
                  tu pedido a la repartidora
                </h3>
              </div>
              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100px"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve">
                <image
                  id="image2"
                  width="100"
                  height="100"
                  href="./envio2.webp"></image>
              </svg>
            </div>
          </div>
          <div className="refund">
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Recibelo como regalo</div>
                <h3 className="footerCardDetails">
                  ¡Todos tus pedidos pueden ir
                  <br />
                  empacados como regalo!
                </h3>
              </div>
              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100px"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve">
                <image
                  id="image3"
                  width="80"
                  height="100"
                  href="./caja-de-regalo1.webp"></image>
              </svg>
            </div>
          </div>
          <div>
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Soporte permanente</div>
                <h3 className="footerCardDetails">
                  Atención personalizada por
                  <br />
                  WhatsApp o correo
                </h3>
              </div>
              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="50"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve">
                <image
                  id="image4"
                  width="80"
                  height="100"
                  href="./wasa2.webp"></image>
              </svg>
            </div>
          </div>
        </div>
        <FooterTop />
        <div className="footerBottom">
          <div className="footerContact">
            <div className="footerBottomTitle">
              Contacto
              <a href="mailto:contacto@enlazo.co" rel="noopener noreferrer">
                contacto@enlazo.co
              </a>
              <a href="https://wa.me/573002936267" rel="noopener noreferrer">
                WhatsApp: +57 3002936267
              </a>
            </div>
          </div>
          <div className="footerWho">
            <div className="footerBottomTitle">
              Quiénes somos
              <Link>
                Somos Enlazo, una tienda en línea donde <br /> además de
                encontrar excelentes precios <br />
                puedes recibir tus productos empacados <br /> como regalo.
                Cuidamos hasta el más <br /> mínimo detalle.
              </Link>
            </div>
          </div>
          <div className="footerLinks">
            <div className="footerBottomTitle">
              Enlaces
              <Link to="/blog">Blog</Link>
              <Link>Nuestros empaques de regalo</Link>
              <Link>Cambios y devoluciones</Link>
              <Link>Vender en Enlazo</Link>
              <Link>Ventas al por mayor</Link>
            </div>
          </div>
        </div>
      </footer>
    );
}
export default Footer