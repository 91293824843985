import { Link, useLocation } from "react-router-dom";
import textAlt from "../utils/textAlt";
import removeAccents from "../utils/removeAccents";
import productsJSON from "../db.json";
import { Helmet } from "react-helmet";
import NotFound from "./NotFound";
function Search() {
  const products = productsJSON.products;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("q");
    if (query === null) {
      return <NotFound />;
    }
  function searchProducts(query) {

  const normalizedQuery = removeAccents(query.toLowerCase());
  const words = normalizedQuery.split(" ");

  const results = products.filter((product) => {
    const name = removeAccents(product.name.toLowerCase());
    const description = removeAccents(product.description.toLowerCase());
    const categories = product.category.map((category) =>
      removeAccents(category.toLowerCase())
    );

    const titleMatches = words.reduce((total, word) => {
      if (name.includes(word)) {
        return total + 1;
      }
      return total;
    }, 0);

    const totalMatches = words.reduce((total, word) => {
      return (
        total +
        (name.includes(word) ? 1 : 0) +
        (description.includes(word) ? 1 : 0) +
        categories.filter((category) => category.includes(word)).length
      );
    }, 0);

    return titleMatches > 0 || totalMatches > 0;
  });

  results.sort((a, b) => {
    const titleMatchesA = words.reduce(
      (total, word) =>
        removeAccents(a.name.toLowerCase()).includes(word) ? total + 1 : total,
      0
    );

    const titleMatchesB = words.reduce(
      (total, word) =>
        removeAccents(b.name.toLowerCase()).includes(word) ? total + 1 : total,
      0
    );

    return titleMatchesB - titleMatchesA;
  });

  return results;
  }
  const results = searchProducts(query);
  return (
  <div className="containerSearch container">
      <Helmet>
        <title>{query} - Enlazo, ¡Lleva tus productos como regalo!</title>
        <meta name="description"
          content="Venta de relojes inteligentes, smartwatch, audifonos inalambricos, Lleva tus productos empacados como regalo"/>
        <link rel="canonical" href={`https://enlazo.co/${location.search}`} />
      </Helmet>
      <div className="filtersSearch">
        <span>Resultados: {results.length}</span>
        <div className="filters">
          <div
            className="filtersTitle"
            style={{ borderTop: "1px solid rgba(0, 0, 0, 0.105)" }}
          >
            <span>Categoría</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 24 24"
              style={{ fill: "#bbbbbb", transform: "msFilter" }}
            >
              <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
            </svg>
          </div>
        </div>
        <div className="filters">
          <div className="filtersTitle">
            <span>Precio</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 24 24"
              style={{ fill: "#bbbbbb", transform: "msFilter" }}
            >
              <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="searchProductsContainer">
        {results.map((product) => (
          <Link
            to={`/${removeAccents(product.name)
              .replace(/\s+/g, "-")
              .toLowerCase()}+${product.id}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              overflow: "hidden",
            }}
            key={product.id}
          >
            <div className="card" style={{ marginTop: "0rem" }}>
              <img
                className="card-img"
                src={product.images[0]}
                alt={textAlt(product.name)}
                style={{ objectFit: "contain" }}
                loading="lazy"
              />
              <div className="card-info">
                <h2 className="textTitle">{product.name}</h2>
              </div>
              <div className="card-footer">
                <span className="price">
                  ${product.price.toLocaleString("es-CO")}
                </span>
                <span className="noPrice noPriceCard">
                  ${(product.price * 1.25).toLocaleString("es-CO")}
                </span>
              </div>
              <div
                className="shipping"
                style={{
                  color: product.stock === 0 ? "gray" : "green",
                }}
              >
                {product.stock === 0 ? "Producto Agotado" : "Envío gratis"}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
export default Search;