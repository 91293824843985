import React, { useState } from "react";
import statesJSON from "../db.json";

const StateDropdown = () => {
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const states = statesJSON.state;

  const selectedDepartmentObject = states.find(
    (department) => department.departamento === selectedDepartment
  );

  const municipalities =
    selectedDepartmentObject && selectedDepartmentObject.ciudades
      ? selectedDepartmentObject.ciudades
      : [];

  const selectStyles = {
    height: "3rem",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid rgba(72, 71, 71, 0.144",
    padding: "0rem 1rem",
    fontSize: '14px'
  };
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  return (
    <div className="checkoutForm">
      <label htmlFor="state" style={{ fontWeight: "500" }}>
        Departamento
      </label>
      <select
        id="state"
        name="state"
        value={selectedDepartment}
        onChange={handleDepartmentChange}
        required
        style={selectStyles}
      >
        <option value="">Selecciona un departamento</option>
        {states.map((department) => (
          <option key={department.id} value={department.departamento} style={selectStyles}>
            {department.departamento}
          </option>
        ))}
      </select>

      {selectedDepartment && (
        <>
          <label htmlFor="city" style={{ fontWeight: "500" }}>
            Municipio
          </label>
          <select id="city" name="city" required style={selectStyles}>
            <option value="">Selecciona un municipio</option>
            {municipalities.map((municipality) => (
              <option key={municipality} value={municipality}>
                {municipality}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};

export default StateDropdown;
