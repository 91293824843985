import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home'
import Product from './pages/Product'
import { useEffect } from 'react';
import Blog from './pages/Blog';
import Search from './pages/Search';
import Header from './components/header';
import Footer from './components/Footer';
import Checkout from './pages/Checkout';
import Cart from "./components/Cart";
function App() {
  function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    return null;
  }
  return (
    <Router>
      <ScrollToTop />
      <Cart />
      <Header />
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route path="/blog" Component={Blog} />
        {/* <Route path="/blog/:article" Component={Article} /> */}
        <Route path="/search" Component={Search} />
        <Route path="/checkout" Component={Checkout} />
        <Route path="/:title" Component={Product} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
