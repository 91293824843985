import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import { useEffect, useState } from "react";

const BannerSlide = () => {
    const [imageSrc, setImageSrc] = useState("");
    const [imageSrc2, setImageSrc2] = useState("");
    const [imageSrc3, setImageSrc3] = useState("");
      const options = {
        type: "loop",
        gap: "1rem",
        autoplay: true,
        pauseOnHover: true,
        resetProgress: false,
        height: "55vh",
      };
      useEffect(() => {
        const handleResize = () => {
          const screenWidth = window.innerWidth;

          if (screenWidth >= 1024) {
            setImageSrc("./banner/banner1-pc.webp");
            setImageSrc2("./banner/banner-pagos-pc.webp");
            setImageSrc3("./banner/banner-descuento-pc.webp");
          } else if (screenWidth >= 768) {
            setImageSrc("./banner/banner1-tablet.webp");
            setImageSrc2("./banner/banner-pagos-tablet.webp");
            setImageSrc3("./banner/banner-descuento-tablet.webp");
          } else {
            setImageSrc("./banner/banner1-mobile.webp");
            setImageSrc2("./banner/banner--pagos-celular.webp");
            setImageSrc3("./banner/banner-descuento-celular.webp");
          }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
  return (
    <div className="wrapper">
      <Splide
        options={options}
        aria-labelledby="autoplay-example-heading"
        hasTrack={false}
      >
        <div style={{ position: "relative" }}>
          <SplideTrack>
            <SplideSlide>
              <img
                src={imageSrc}
                alt="descuento-25%-relojes-audifonos-enlazo-promocion"
                width="100%"
                height="100%"
                style={{ borderRadius: "5px" }}
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src={imageSrc3}
                alt="descuento-25%-relojes-audifonos-enlazo-promocion"
                width="100%"
                height="100%"
                style={{ borderRadius: "5px" }}
                loading="lazy"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src={imageSrc2}
                alt="descuento-25%-relojes-audifonos-enlazo-promocion"
                width="100%"
                height="100%"
                style={{ borderRadius: "5px" }}
                loading="lazy"
              />
            </SplideSlide>
          </SplideTrack>
        </div>

        <div className="splide__progress">
          <div className="splide__progress__bar" />
        </div>
      </Splide>
    </div>
  );
};

export default BannerSlide;
