import { useCart } from "../utils/CartProvider";
import { useState, useEffect, useRef, } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    const { cart, toggleCartVisibility} = useCart();
    const [hide, setHide] = useState(false);
    const [hideSearch, setHideSearch] = useState(false)
    const [isHeaderHidden, setIsHeaderHidden] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const searchRef = useRef(null);
    const [query, setQuery] = useState("");
    const navigate = useNavigate()
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
          setHideSearch(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY
        
      if (scrollTop > lastScrollTop) {
        setIsHeaderHidden(true); 
      } 
      else if (scrollTop => 100) {
        setIsHeaderHidden(false);
      } else {
        setIsHeaderHidden(false);
      }

      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

    return (
      <header
        className="header"
        style={{
          transform: isHeaderHidden ? "translateY(-100%)" : "translateY(0)",
          transition: "0.4s ease",
        }}
      >
        <div className="navbarTop">
          <label className="burger" onClick={() => setHide(!hide)}>
            <input type="checkbox" onClick={(e) => e.stopPropagation()} />
            <span></span>
            <span></span>
            <span></span>
          </label>
          <div
            className="navbarHide"
            style={{
              position: "fixed",
              backgroundColor: "white",
              display: hide ? "flex" : "none",
              marginTop: "6.5vh",
              height: "100vh",
              width: "100vw",
              left: 0,
            }}
          >
            <div className="navBarListHide">
              <Link to={"/search?q=Tecnología"} onClick={() => setHide(!hide)}>
                Tecnología
              </Link>
              <Link
                to={"/search?q=Audifonos%20Inalambricos"}
                onClick={() => setHide(!hide)}
              >
                Audifonos Inalámbricos
              </Link>
              <Link
                to={"/search?q=Reloj%20inteligente"}
                onClick={() => setHide(!hide)}
              >
                Relojes Inteligentes
              </Link>
              <Link to={"/search?q=Tecnología"} onClick={() => setHide(!hide)}>
                Todos los productos
              </Link>
              <Link to={"/blog"} onClick={() => setHide(!hide)}>
                Blog
              </Link>
              {/* <Link to={"/"} onClick={() => setHide(!hide)}>
                Quiénes Somos
              </Link> */}
              <span>¿Necesitas ayuda?</span>
              <a
                href="https://wa.me/573002936267"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(189, 189, 189, 1)",
                    transform: "msFilter",
                    marginRight: "0.5rem",
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
                  ></path>
                </svg>
                WhatsApp
              </a>
              <a href="mailto:contacto@enlazo.co">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(189, 189, 189, 1)",
                    transform: "msFilter",
                    marginRight: "0.5rem",
                  }}
                >
                  <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path>
                </svg>
                Correo
              </a>
              <span>Nuestras redes sociales</span>
              <a
                href="https://facebook.com/enlazoColombia"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(189, 189, 189, 1)",
                    marginRight: "0.5rem",
                    transform: "msFilter",
                  }}
                >
                  <path d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"></path>
                </svg>
                Facebook
              </a>
              <a
                href="https://instagram.com/enlazo.co"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(189, 189, 189, 1)",
                    transform: "msFilter",
                    marginRight: "0.5rem",
                  }}
                >
                  <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                  <circle cx="16.806" cy="7.207" r="1.078"></circle>
                  <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                </svg>
                Instagram
              </a>
              <a href="https://youtube.com/enlazoColombia">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(189, 189, 189, 1)",
                    marginRight: "0.5rem",
                    transform: "msFilter",
                  }}
                >
                  <path d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path>
                </svg>
                YouTube
              </a>
            </div>
          </div>
          <Link to="/">
            <img
              src="./logo.webp"
              alt="enlazo-logo"
              style={{ width: "100px", height: "50px" }}
            />
          </Link>
          <div
            className="searchHide"
            style={{ display: hideSearch ? "flex" : "none" }}
            ref={searchRef}
          >
            <label htmlFor="search2" />
            <input
              placeholder="Busca productos"
              className="searchInput2"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && query.length > 0) {
                  navigate(`/search?q=${encodeURIComponent(query)}`);
                }
              }}
              id="search2"
            />
            <Link
              to={`/search?q=${encodeURIComponent(query)}`}
              aria-label="Buscar"
              title="Buscar"
            >
              <svg
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="searchLogo2"
                style={{
                  borderLeft: "1px solid rgba(72, 71, 71, 0.144)",
                  marginRight: "0rem",
                  marginTop: "-0.75rem",
                }}
              >
                <g id="Interface / Search_Magnifying_Glass">
                  <path
                    id="Vector"
                    d="M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                    stroke="#48474789"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </Link>
          </div>
          <div className="searchContainer">
            <label htmlFor="search" />
            <input
              placeholder="Busca productos"
              className="searchInput"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && query.length > 0) {
                  navigate(`/search?q=${encodeURIComponent(query)}`);
                }
              }}
              id="search"
            />
            <Link
              to={`/search?q=${encodeURIComponent(query)}`}
              title="Buscar producto"
            >
              <svg
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="searchLogo"
              >
                <g id="Interface">
                  <path
                    id="Vector"
                    d="M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                    stroke="#48474789"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </Link>
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="searchLogo2"
              onClick={() => {
                setHideSearch(!hideSearch);
              }}
              style={{ right: ".6rem", top: "0.9rem" }}
            >
              <g id="Interface / Search_Magnifying_Glass">
                <path
                  id="Vector"
                  d={
                    "M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                  }
                  stroke="#6DF76D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
          <div
            className="cartLogo"
            onClick={(e) => {
              e.stopPropagation();
              toggleCartVisibility();
            }}
          >
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill="#6DF76D"
              className="bi bi-cart2"
            >
              <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
            </svg>
            <span className="cartText">Carrito</span>
            <span className="cartLength">{cart.length}</span>
          </div>
        </div>
        <div className="navBarList">
          <Link to={"/search?q=Tecnología"}>Tecnología</Link>
          <Link to={"/search?q=Audifonos%20Inalambricos"}>
            Audifonos Inalámbricos
          </Link>
          <Link to={"/search?q=Reloj%20inteligente"}>Relojes Inteligentes</Link>
          <Link to={"/search?q=Tecnología"}>Todos los productos</Link>
          <Link to={"/blog"}>Blog</Link>
          {/* <Link to={"/"}>Quiénes Somos</Link> */}
        </div>
      </header>
    );
}
export default Header;