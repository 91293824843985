import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import WhatsApp from './utils/WhatsApp';
import { CartProvider } from './utils/CartProvider';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CartProvider>
      <App />
    </CartProvider>
      <WhatsApp />
  </React.StrictMode>
);

reportWebVitals();
