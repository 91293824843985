import { Helmet } from "react-helmet";
import { useCart } from "../utils/CartProvider"
import emailjs from "@emailjs/browser";
import { useRef, useState} from "react";
import StateDropdown from "../utils/StateDropdown";
import NotFound from "./NotFound";
export default function Checkout(){
    const {cart, removeItemFromCart, increaseQuantity, decreaseQuantity, calculateTotal} = useCart()
    const [selectedPayment, setSelectedPayment] = useState("");
    const radioTileStyle = {
      display: "flex",
      flexDirection: "row",
      height: "3rem",
      justifyContent: "space-between",
      padding: "0rem 1rem",
      alignItems: "center",
      boxShadow: "none",
      border: "1px solid rgba(72, 71, 71, 0.144)",
      borderRadius: "0px"
    };
    const labelWeigthStyle = {fontWeight: '500'};
    const imgStyle = {objectFit: 'contain'}
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAIL_SERVICE,
          process.env.REACT_APP_EMAIL_TEMPLATE,
          form.current,
          process.env.REACT_APP_EMAIL_KEY
        )
    };
    return cart.length > 0 ? (
      <div className="checkoutContainer container">
        <Helmet>
          <title>Comprar Ahora - Enlazo</title>
        </Helmet>
        <div className="checkoutFormContainer">
          <form className="checkoutForm" ref={form} onSubmit={sendEmail}>
            <h2>Contacto</h2>
            <label htmlFor="email" style={labelWeigthStyle}>
              Correo
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="ejemplo@gmail.com"
              required
            />
            <h2>Datos para la entrega</h2>
            <label htmlFor="name" style={labelWeigthStyle}>
              Nombres y Apellidos
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Ejemplo: Andrés Rodríguez"
              required
            />
            <label htmlFor="cedula" style={labelWeigthStyle}>
              Cédula
            </label>
            <input
              type="text"
              id="cedula"
              name="cedula"
              placeholder="Ejemplo: 1003340103"
              required
            />
            <label htmlFor="address" style={labelWeigthStyle}>
              Dirección
            </label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Ejemplo: Calle 14 #12-72"
              required
            />
            <label htmlFor="addressPlus" style={labelWeigthStyle}>
              Datos adicionales
            </label>
            <input
              type="text"
              id="addressPlus"
              name="addressPlus"
              placeholder="Ejemplo: Casa Roja, Rejas Blancas"
              required
            />
            <StateDropdown />
            <label htmlFor="phone" style={labelWeigthStyle}>
              Número de celular
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              placeholder="Ejemplo: 3002936267"
              required
            />
            <h2>Medios de pago</h2>
            <div
              className="paymentInputs"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="payment"
                  value="Nequi o DaviPlata"
                  onChange={(e) => setSelectedPayment(e.target.value)}
                />
                <span
                  className="radio-tile radioTile"
                  style={{
                    ...radioTileStyle,
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <span style={labelWeigthStyle}>Nequi o DaviPlata</span>
                  <span
                    style={{
                      backgroundColor: "green",
                      borderRadius: "5px",
                      color: "white",
                      padding: "3px",
                      fontWeight: "500",
                    }}
                  >
                    Pagas: $
                    {(
                      calculateTotal() -
                      ((cart.some((item) => item.price >= 37900) &&
                        calculateTotal() >= 60000) ||
                      (cart.length > 0 &&
                        cart[0].quantity > 1 &&
                        cart[0].price > 37900)
                        ? cart.length > 1 || cart[0].quantity > 1
                          ? 10000 - -calculateTotal() * 0.1
                          : 0
                        : cart.some((item) => item.price <= 37900)
                        ? calculateTotal() >= 60000
                          ? -18000
                          : cart.some((item) =>item.shipping === 'pay') ? -18000 : 0
                        : 0)
                    ).toLocaleString("es-CO")}
                  </span>
                  <span style={{ display: "flex", gap: "5px" }}>
                    <img
                      src="./payments/nequi.webp"
                      width="38px"
                      alt=""
                      style={imgStyle}
                      loading="lazy"
                    />
                    <img
                      src="./payments/dplata.webp"
                      width="38px"
                      alt=""
                      style={imgStyle}
                      loading="lazy"
                    />
                  </span>
                </span>
              </label>
              <label
                className={
                  selectedPayment === "Nequi o DaviPlata"
                    ? "radioContent"
                    : "hidden"
                }
              >
                Paga por transferencia a Nequi o DaviPlata.
                <br />
                <br /> <strong>Nequi: 3042215803</strong>
                <br />
                <strong>DaviPlata: 3007748745</strong>
                <br />
                Envíanos el comprobante al número de WhatsApp:{" "}
                <strong>3002936267</strong>
                <br />
                <br />
                Al presionar "Comprar Ahora" te contactaremos por el correo y
                número que ingresaste.
                <br />
                <br />
              </label>
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="payment"
                  value="Contraentrega"
                  onChange={(e) => setSelectedPayment(e.target.value)}
                />
                <span className="radio-tile radioTile" style={radioTileStyle}>
                  <span style={labelWeigthStyle}>Contra entrega</span>
                  <span
                    style={{
                      backgroundColor: "green",
                      borderRadius: "5px",
                      color: "white",
                      padding: "3px",
                      fontWeight: "500",
                    }}
                  >
                    Pagas: $
                    {(
                      calculateTotal() -
                      ((cart.some((item) => item.price >= 37900) &&
                        calculateTotal() >= 60000) ||
                      (cart.length > 0 &&
                        cart[0].quantity > 1 &&
                        cart[0].price > 37900)
                        ? cart.length > 1 || cart[0].quantity > 1
                          ? -(-calculateTotal()) * 0.1
                          : -10000
                        : cart.some((item) => item.price <= 37900)
                        ? calculateTotal() >= 60000
                          ? -28000
                          : cart.some((item) =>item.shipping === 'pay') ? -28000 : -10000
                        : -10000)
                    ).toLocaleString("es-CO")}
                  </span>
                  <img
                    src="./payments/contra.webp"
                    width="38px"
                    alt=""
                    style={imgStyle}
                    loading="lazy"
                  />
                </span>
              </label>
              <label
                className={
                  selectedPayment === "Contraentrega"
                    ? "radioContent"
                    : "hidden"
                }
              >
                Pagas en efectivo al momento de recibir. <br />
                <br />
                Compra mínima: <strong>$50.000</strong>
                <br />
                Al presionar "Comprar Ahora" te contactaremos por el correo y
                número que ingresaste.
                <br />
                <br />
              </label>
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="payment"
                  value="Pago mixto"
                  onChange={(e) => setSelectedPayment(e.target.value)}
                />
                <span className="radio-tile radioTile" style={radioTileStyle}>
                  <span style={labelWeigthStyle}>Pago Mixto: 50 / 50</span>
                  <span
                    style={{
                      backgroundColor: "green",
                      borderRadius: "5px",
                      color: "white",
                      padding: "3px",
                      fontWeight: "500",
                    }}
                  >
                    Pagas: $
                    {(
                      calculateTotal() -
                      ((cart.some((item) => item.price >= 37900) &&
                        calculateTotal() >= 60000) ||
                      (cart.length > 0 &&
                        cart[0].quantity > 1 &&
                        cart[0].price > 37900)
                        ? cart.length > 1 || cart[0].quantity > 1
                          ? 6000 - -calculateTotal() * 0.1
                          : -4000
                        : cart.some((item) => item.price <= 37900)
                        ? calculateTotal() >= 60000
                          ? -22000
                          : cart.some((item) =>item.shipping === 'pay') ? -22000 : -4000
                        : -4000)
                    ).toLocaleString("es-CO")}
                  </span>
                  <img
                    src="./payments/contra.webp"
                    width="38px"
                    alt=""
                    style={imgStyle}
                    loading="lazy"
                  />
                </span>
              </label>
              <label
                className={
                  selectedPayment === "Pago mixto" ? "radioContent" : "hidden"
                }
              >
                Este método de pago te permite pagar en este momento el 50% de
                la compra por Nequi o DaviPlata, y el otro 50% en efectivo una vez recibas
                el producto. <br />
                <br />
                Compra mínima: <strong>$75.000</strong>
                <br />
                Al presionar "Comprar Ahora" te contactaremos por el correo y
                número que ingresaste.
                <br />
                <br />
              </label>
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="payment"
                  value="Mercado Pago"
                  onChange={(e) => setSelectedPayment(e.target.value)}
                />
                <span className="radio-tile radioTile" style={radioTileStyle}>
                  <span style={labelWeigthStyle}>Mercado Pago</span>
                  <span
                    style={{
                      backgroundColor: "green",
                      borderRadius: "5px",
                      color: "white",
                      padding: "3px",
                      fontWeight: "500",
                    }}
                  >
                    Pagas: $
                    {(
                      calculateTotal() -
                      ((cart.some((item) => item.price >= 37900) &&
                        calculateTotal() >= 60000) ||
                      (cart.length > 0 &&
                        cart[0].quantity > 1 &&
                        cart[0].price > 37900)
                        ? cart.length > 1 || cart[0].quantity > 1
                          ? 4000 - -calculateTotal() * 0.1
                          : -5000
                        : cart.some((item) => item.price <= 37900)
                        ? calculateTotal() >= 60000
                          ? -23000
                          : cart.some((item) =>item.shipping === 'pay') ? -23000 : -5000
                        : -5000)
                    ).toLocaleString("es-CO")}
                  </span>
                  <span style={{ display: "flex", gap: "5px" }}>
                    <img
                      src="./payments/mpago.webp"
                      width="48px"
                      alt=""
                      style={imgStyle}
                      loading="lazy"
                    />
                    <img
                      src="./payments/pse.webp"
                      width="35px"
                      alt=""
                      style={imgStyle}
                      loading="lazy"
                    />
                    <img
                      src="./payments/efecty.webp"
                      width="38px"
                      alt=""
                      style={imgStyle}
                      loading="lazy"
                    />
                    <img
                      src="./payments/mastercard.webp"
                      width="38px"
                      alt=""
                      style={imgStyle}
                      loading="lazy"
                    />
                  </span>
                </span>
              </label>
              <label
                className={
                  selectedPayment === "Mercado Pago" ? "radioContent" : "hidden"
                }
              >
                Al hacer click en "Comprar Ahora" recibirás por el WhatsApp del
                número que ingresaste un enlace por donde podrás realizar el
                pago.
                <br />
                <br />
              </label>
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="payment"
                  value="Addi"
                  disabled
                />
                <span
                  className="radio-tile radioTile"
                  style={{ ...radioTileStyle, backgroundColor: "#B5B5B5" }}
                >
                  <span style={labelWeigthStyle}>Addi</span>
                  <span
                    style={{
                      backgroundColor: "gray",
                      borderRadius: "5px",
                      padding: "1px",
                      fontWeight: "500",
                    }}
                  >
                    Próximamente disponible
                  </span>
                  <img
                    src="./payments/addi.webp"
                    width="48px"
                    alt=""
                    style={imgStyle}
                    loading="lazy"
                  />
                </span>
              </label>
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="payment"
                  value="Sistecrédito"
                  disabled
                />
                <span
                  className="radio-tile radioTile"
                  style={{
                    ...radioTileStyle,
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    backgroundColor: "#B5B5B5",
                  }}
                >
                  <span style={labelWeigthStyle}>Sistecrédito</span>
                  <span
                    style={{
                      backgroundColor: "gray",
                      borderRadius: "5px",
                      fontWeight: "500",
                    }}
                  >
                    Próximamente disponible
                  </span>
                  <img
                    src="./payments/sistecredito.webp"
                    width="60px"
                    alt=""
                    style={imgStyle}
                    loading="lazy"
                  />
                </span>
              </label>
              {cart.map((product) => (
                <label
                  style={{
                    position: "absolute",
                    color: "transparent",
                    visibility: "hidden",
                  }}
                  key={product.id}
                >
                  <input
                    className="radio-input"
                    type="text"
                    name="product"
                    value={`id: ${product.id} + precio: ${product.price} + Nombre: ${product.productName} + Color: ${product.variation}`}
                  />
                </label>
              ))}
              <label
                style={{
                  position: "absolute",
                  color: "transparent",
                  visibility: "hidden",
                }}
              >
                <input type="text" id="city" name="city" value="" />
              </label>
              <label
                style={{
                  position: "absolute",
                  color: "transparent",
                  visibility: "hidden",
                }}
              >
                <input type="text" id="state" name="state" value="" />
              </label>
            </div>
            <input type="submit" value="Comprar Ahora" className="submit" />
          </form>
        </div>
        <div className="checkoutDetails">
          <div
            className="cartMiddle cartMiddleCheck"
            style={{ backgroundColor: "white" }}
          >
            {cart.map((cart) => (
              <article className="cart__card" key={cart.id}>
                <div className="cart__box">
                  <img src={cart.image} alt={cart.alt} className="cart__img" />
                </div>
                <div className="cart__details">
                  <h3 className="cart__title">
                    {cart.productName}{" "}
                    <span className="cart__price">
                      ${cart.price.toLocaleString("es-CO")}
                    </span>
                  </h3>

                  <div className="cart__amount">
                    <div className="cart__amount-content">
                      <span
                        className="cart__amount-box"
                        data-id="1"
                        onClick={() => decreaseQuantity(cart.id)}
                      >
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="12"
                            viewBox="0 0 24 24"
                            style={{
                              fill:
                                cart.quantity === 1
                                  ? "rgba(0, 0, 0, 0.118)"
                                  : "black",
                              transform: "msFilter:",
                            }}
                          >
                            <path d="M5 11h14v2H5z"></path>
                          </svg>
                        </i>
                      </span>

                      <span className="cart__amount-number">
                        {cart.quantity}
                      </span>

                      <span
                        className="cart__amount-box "
                        data-id="1"
                        onClick={() => increaseQuantity(cart.id)}
                      >
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="12"
                            viewBox="0 0 24 24"
                            style={{
                              fill: "black",
                              transform: "msFilter:",
                            }}
                          >
                            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                          </svg>
                        </i>
                      </span>
                    </div>

                    <i
                      className="cart__amount-trash deleteToCart"
                      data-id="1"
                      onClick={() => removeItemFromCart(cart.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        style={{
                          fill: "rgba(0, 0, 0, 1)",
                          transform: "msFilter",
                        }}
                      >
                        <path d="M15 2H9c-1.103 0-2 .897-2 2v2H3v2h2v12c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V8h2V6h-4V4c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm8 16H7V8h10v12z"></path>
                      </svg>
                    </i>
                  </div>

                  <span className="cart__subtotal">
                    <span className="cart__subtotal-price">
                      ${(cart.quantity * cart.price).toLocaleString("es-CO")}
                    </span>
                  </span>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <NotFound />
    );
}