import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import textAlt from '../utils/textAlt'
import removeAccents from '../utils/removeAccents'
const ProductSlide = ({ products, slideTitle }) => {
    const [perPage, setPerPage] = useState(calculatePerPage());
    useEffect(() => {
      function handleResize() {
        setPerPage(calculatePerPage());
      }

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    function calculatePerPage() {
      if (window.innerWidth >= 1200) {
        return 4;
      } else if (window.innerWidth >= 768) {
        return 3;
      } else {
        return 2;
      }
    }

  return (
    <div className="wrapper">
      <h2>{slideTitle}</h2>
      <Splide
        options={{
          perPage: perPage,
          perMove: 2,
          height: "100%",
          rewind: false,
          gap: "0.5rem",
          pagination: false,
        }}
        aria-labelledby={slideTitle}
        aria-roledescription={slideTitle}
        role={slideTitle}
      >
        {products.map((product) => (
          <SplideSlide key={product.id}>
            <Link
              to={`/${removeAccents(product.name)
                .replace(/\s+/g, "-")
                .toLowerCase()}+${product.id}`}
              style={{ textDecoration: "none", color: "inherit", overflow: "hidden" }}
            >
              <div
                className="card"
                >
                <img
                  className="card-img"
                  src={product.images[0]
                  }
                  alt={textAlt(product.name)}
                  style={{ objectFit: "contain" }}
                  loading="lazy"
                />
                <div className="card-info">
                  <h2 className="textTitle">{product.name}</h2>
                </div>
                <div className="card-footer">
                  <span className="price">
                    ${product.price.toLocaleString("es-CO")}
                  </span>
                  <span className="noPrice noPriceCard">
                    ${(product.price * 1.25).toLocaleString("es-CO")}
                  </span>
                </div>
                <div
                  className="shipping"
                  style={{
                    color: product.stock === 0 ? "gray" : "green"
                  }}
                >
                  {product.shipping === 'pay' ? "" : "Envío gratis"}
                </div>
              </div>
            </Link>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};
export default ProductSlide;