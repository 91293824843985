import "../App.css";
import productsJSON from "../db.json";
import ProductSlide from "../components/productSlide";
import BannerSlide from "../components/bannerSlide";
import { Helmet } from "react-helmet";

function Home() {
  const products = productsJSON.products;
  const smartWatch = products.filter((product) => {
    return product.category.includes("Relojes Inteligentes");
  });
  const earphones = products.filter((product) => {
    return product.category.includes("Audífonos Inalámbricos");
  });
  const other = products.filter((product) => {
    return product.category.includes("Otros");
  });
  return (
    <div className="container">
      <Helmet>
        <meta
          name="description"
          content="¡Lleva tus productos empacados como regalo! Relojes inteligentes, Smartwatch, Audifonos Inalambricos, Accesorios tecnológicos - Enlazo"
        />
        <title>
          Enlazo - Relojes Inteligentes, Audifonos, ¡Llevalos Como Regalo!
        </title>
        <link rel="canonical" href="https://enlazo.co" />
      </Helmet>
      <h1 style={{ color: "transparent", position: "absolute" }}>
        Enlazo - Relojes Inteligentes, Tecnología, Audifonos inalambricos,
        Envíos gratis
      </h1>
      <BannerSlide />
      <ProductSlide
        products={earphones}
        slideTitle={"Audífonos Inalámbricos"}
      />
      <ProductSlide products={smartWatch} slideTitle={"Relojes Inteligentes"} />
      <ProductSlide products={products} slideTitle={"Tecnología"} />
      <ProductSlide products={other} slideTitle={"Accesorios tecnológicos"} />
    </div>
  );
}

export default Home;
