function Blog() {
  return (
    <div className="containerBlog container">
      <h1> Blog </h1>
      <p> This is my blog! </p>

      <section className="cardsBlog">
        <article className="horizontalBlog cardBlog">
          <img className="card__img_blog" src="https://picsum.photos/256/196/?image=321" alt=""/>
          <div className="card__content_blog">
            <div className="card__type_blog">article</div>
            <div className="card__title_blog">
              Test Post: Electric Boogaloo
            </div>
            <div className="card__date_blog">01 Octubre 2023 &middot; <span className="card__time-to-read_blog">5 min read</span></div>
            <div className="card__excerpt_blog">
              This is a test article, in a perculiar context, to see whether grid and flexbox works to make a blog...
            </div>
            <div className="card__tags_blog">
              <div className="tag_blog"><i className="fa fa-tag"></i>test</div>
              <div className="tag_blog"><i className="fa fa-tag"></i>test</div>
            </div>
          </div>
        </article>
      </section>
</div>
  );
}
export default Blog;