export default function shippingDays(cantidadDias) {
  const hoy = new Date();
  const diaActual = hoy.getDay();

  const diasHabiles = [];
  let dia = diaActual;
  let diasAgregados = 0;

  while (diasAgregados < cantidadDias) {
    dia = (dia + 1) % 7; 

    if (dia !== 0) {
      diasHabiles.push(dia);
      diasAgregados++;
    }
  }
  const nombresDias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const primerDia = nombresDias[diasHabiles[1]];
  const ultimoDia = nombresDias[diasHabiles[diasHabiles.length - 1]];

  return ` ${primerDia} y ${ultimoDia}`;
}