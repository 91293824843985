import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const NotFound = () =>{

    return (
      <div className="container notFoundContainer" style={{ height: "80vh" }}>
        <Helmet>
          <title>Enlazo - Página no encontrada</title>
          <meta
            name="description"
            content="¡Lleva tus productos empacados como regalo! Relojes inteligentes, Smartwatch, Audifonos Inalambricos, Accesorios tecnológicos"
          />
        </Helmet>
        <img src="./notfound.webp" alt="" width="200px" />
        <h1 style={{ fontSize: "1.3rem" }}>Parece que esta página no existe</h1>
        <Link
          to="/"
          style={{ fontSize: "1.1rem", fontWeight: "500", color: "blue" }}
        >
          Ir a la página principal
        </Link>
      </div>
    );
}
export default NotFound