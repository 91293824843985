import productsJSON from "../db.json";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Link, useParams } from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import textAlt from "../utils/textAlt";
import shippingDays from "../utils/shippingDays";
import { Helmet } from "react-helmet";
import { useCart } from "../utils/CartProvider";
import NotFound from "./NotFound";

const Product = () => {
  const [quantity, setQuantity] = useState(1);
  const { title } = useParams();
  const products = productsJSON.products;
  const mainRef = useRef(null);
  const thumbsRef = useRef(null);
  const {addToCart, toggleCartVisibility} = useCart();
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };
  const handleQuantityChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setQuantity(newValue);
    }
  };
  useEffect(() => {
    if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
      mainRef.current.sync(thumbsRef.current.splide);
    }
  }, []);
  const renderSlides = () => {
    return selectedProduct.images.map((image, index) => (
      <SplideSlide key={index}>
        <img
          src={image}
          width="100%"
          height="100%"
          style={{ objectFit: "contain" }}
          alt={textAlt(selectedProduct.name)}
        />
      </SplideSlide>
    ));
  };
  const mainOptions = {
    type: "loop",
    perPage: 1,
    perMove: 1,
    gap: "1rem",
    pagination: false,
    height: "30rem",
    width: "35rem",
    arrows: false,
  };

  const thumbsOptions = {
    type: "slide",
    rewind: true,
    gap: "0.5rem",
    pagination: false,
    fixedWidth: 80,
    fixedHeight: 80,
    cover: true,
    focus: "center",
    isNavigation: true,
    width: "35rem",
    height: "10rem",
    arrows: false,
  };
  const selectedProduct = products.find((product) =>{
      const linkParts = title.split("+");
      const productId = linkParts[linkParts.length - 1];
      return product.id.toString() === productId;
    }
  );
  const handleAddToCart = () => {
    toggleCartVisibility();
    const newProduct = {
      id: selectedId,
      productName: selectedProduct.name,
      price: selectedProduct.price,
      quantity: quantity,
      image: selectedImage,
      alt: textAlt(selectedProduct.name),
      color: selectedColor,
      variation: selectedVariation,
      shipping: selectedProduct.shipping
    };
    addToCart(newProduct);
  };
  
  const [selectedColor, setSelectedColor] = useState( selectedProduct ?
    selectedProduct.variations[0].name : ''
  );
  const [selectedImage, setSelectedImage] = useState( selectedProduct ?
    selectedProduct.variations[0].images : ''
  );
  const [selectedId, setSelectedId] = useState( selectedProduct ?
    selectedProduct.id : ''
  );
  const [selectedVariation, setSelectedVariation] = useState("")
  const setSelectedColorName = (colorName) => {
    setSelectedColor(colorName);
    const selectedVariation = selectedProduct.variations.find(
      (variation) => variation.name === colorName
    );
    setSelectedImage(selectedVariation.images);
    setSelectedId(selectedVariation.id)
    setSelectedVariation(selectedVariation.name)
  };

  const descriptionLines = selectedProduct ? selectedProduct.description.split("\n") : '';
  const specificationsLines = selectedProduct ? selectedProduct.specifications.split("\n") : '';
  const packageLines = selectedProduct ? selectedProduct.package.split("\n"): '';
  return selectedProduct ? (
    <div className="productContainer container ">
      <Helmet>
        <title>{selectedProduct.name}</title>
        <meta
          name="description"
          content={
            "¡Llevalo empacado como regalo! " +
            selectedProduct.specifications.substring(0, 150)
          }
        />
        <link rel="canonical" href={`https://enlazo.co/${title}`} />
      </Helmet>
      <div
        className="wrapperProduct"
        style={{
          backgroundColor: "white",
          marginTop: "0rem",
          border: "1px solid rgba(72, 71, 71, 0.144)",
          overflow: "hidden",
        }}
      >
        <Splide
          options={mainOptions}
          ref={mainRef}
          aria-labelledby={textAlt(selectedProduct.name)}
        >
          {renderSlides()}
        </Splide>

        <Splide
          options={thumbsOptions}
          ref={thumbsRef}
          aria-label={textAlt(selectedProduct.name)}
          style={{ border: "1px solid rgba(0, 0, 0, 0.105)" }}
        >
          {renderSlides()}
        </Splide>
      </div>
      <div className="detailsProduct">
        <span className="detailsProductSold">
          {" "}
          <span
            style={{
              borderRight: "1px solid rgba(72, 71, 71, 0.444)",
              paddingRight: "0.6rem",
              marginRight: "0.5rem",
            }}
          >
            Nuevo
          </span>{" "}
          +100 Vendidos
        </span>
        <h1>{selectedProduct.name}</h1>
        <span className="detailsProductOffer">Hoy -25%</span>
        <h2 style={{ marginTop: "1rem" }}>
          <span className="price">
            ${selectedProduct.price.toLocaleString("es-CO")}
          </span>
          <span className="noPrice">
            ${(selectedProduct.price * 1.25).toLocaleString("es-CO")}
          </span>
        </h2>
        <span style={{ marginTop: "1rem" }}>
          Color:{" "}
          <span style={{ fontWeight: "600", fontSize: "20px" }}>
            {selectedColor}
          </span>
        </span>
        <div className="detailsProductVariations">
          {selectedProduct.variations.map((variation, index) => (
            <div
              className="radio-inputs"
              key={index}
              style={{ display: "grid" }}
            >
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="engine"
                  onChange={() => setSelectedColorName(variation.name)}
                  defaultChecked={index === 0}
                  style={{ minHeight: "80px" }}
                />
                <span className="radio-tile" style={{width: '80px'}}>
                  <img
                    src={variation.images}
                    width="70rem"
                    height="70rem"
                    alt={textAlt(selectedProduct.name)}
                    style={{ objectFit: "contain" }}
                  />
                </span>
              </label>
            </div>
          ))}
        </div>

        <div
          className="detailsProductShipping"
          style={{ marginTop: "1rem", position: "relative" }}
        >
          <img
            src="./envio2.webp"
            style={{ position: "absolute", width: "2.2rem", top: "-0.3rem" }}
            alt=""
          />
          <h3
            style={{
              position: "relative",
              marginLeft: "2.5rem",
              color: "green",
            }}
          >
            {selectedProduct.shipping === "free"
              ? `Llega gratis entre ${shippingDays(3)}`
              : `Llega por $18.000 entre ${shippingDays(3)}`}
          </h3>
        </div>
        {selectedProduct.shipping ===
          "pay" ? (
            <span style={{ color: "rgba(72, 71, 71, 0.844)" }}>
              Si añades este producto junto a otro que tenga envío gratis el
              envío será gratis.
            </span>
          ) : <></>}
        <div className="detailsProductQuantity">
          <label htmlFor="quantity">Cantidad:</label>
          <div className="number-control">
            <span
              className="cart__amount-box"
              data-id="1"
              onClick={decreaseQuantity}
              style={{
                borderBottomLeftRadius: "3px",
                borderTopLeftRadius: "3px",
              }}
            >
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="12"
                  viewBox="0 0 24 24"
                  style={{
                    fill: quantity === 1 ? "rgba(0, 0, 0, 0.118)" : "black",
                    transform: "msFilter:",
                  }}
                >
                  <path d="M5 11h14v2H5z"></path>
                </svg>
              </i>
            </span>
            <input
              type="number"
              name="number"
              className="number-quantity"
              value={quantity}
              inputMode="none"
              onChange={handleQuantityChange}
              id="quantity"
            />
            <span
              className="cart__amount-box "
              data-id="1"
              onClick={increaseQuantity}
              style={{
                borderBottomRightRadius: "3px",
                borderTopRightRadius: "3px",
              }}
            >
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="12"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "black",
                    transform: "msFilter:",
                  }}
                >
                  <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                </svg>
              </i>
            </span>
          </div>
        </div>
        <div className="detailsProductBuy">
          <Link
            className="productButton"
            to="/checkout"
            onClick={() => handleAddToCart()}
          >
            Comprar ahora
          </Link>
          <span onClick={() => handleAddToCart()} className="productButton">
            Añadir al carrito
          </span>
        </div>
        <span style={{ marginTop: "0.5rem", fontWeight: "500" }}>
          Compra segura, recibe lo que esperabas o te devolvemos tu dinero
        </span>
      </div>
      <div className="descriptionProduct">
        <h2>Descripción</h2>
        <span>
          {descriptionLines.map((line, index) => (
            <p key={index}>{line}</p>
          ))}
          <p>
            ¿Tienes dudas? Pregunta por{" "}
            <a
              href="https://wa.me/573002936267"
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: "blue", textDecoration: 'underline' }}
            >
              WhatsApp
            </a>
          </p>
        </span>
      </div>
      <div className="containerAccordion">
        <details className="accordion">
          <summary className="accordion-header">
            <div className="accordion-header-content">
              <h2 className="accordion-header-content-title">
                Especificaciones
              </h2>
            </div>
          </summary>
          <div className="accordion-content">
            <h2 className="accordion-content-text">
              {specificationsLines.map((line, index) => (
                <h3 key={index}>{line}</h3>
              ))}
            </h2>
          </div>
        </details>
        <details className="accordion">
          <summary className="accordion-header">
            <div className="accordion-header-content">
              <h2 className="accordion-header-content-title">
                El paquete incluye
              </h2>
            </div>
          </summary>
          <div className="accordion-content">
            <h2 className="accordion-content-text">
              {packageLines.map((line, index) => (
                <h3 key={index}>{line}</h3>
              ))}
            </h2>
          </div>
        </details>
        <details className="accordion">
          <summary className="accordion-header">
            <div className="accordion-header-content">
              <h2 className="accordion-header-content-title">Garantía</h2>
            </div>
          </summary>
          <div className="accordion-content">
            <div className="accordion-content-text">
              <h3>
                Todos nuestros productos cuentan con garantía de 1 mes por
                defectos de fábrica.
              </h3>
            </div>
          </div>
        </details>
        <details className="accordion" style={{ marginBottom: "0" }}>
          <summary className="accordion-header">
            <div className="accordion-header-content">
              <h2 className="accordion-header-content-title">Quienes Somos</h2>
            </div>
          </summary>
          <div className="accordion-content">
            <span className="accordion-content-text">
              <h3>
                Somos Enlazo, una tienda en línea donde además de encontrar
                excelentes precios puedes recibir tus productos empacados como
                regalo. Cuidamos hasta el más mínimo detalle.
              </h3>
            </span>
          </div>
        </details>
      </div>
    </div>
  ) : (
    <NotFound/>
  );
};

export default Product;
