import { Link } from "react-router-dom";
import { useCart } from "../utils/CartProvider"
import { useEffect, useRef } from "react";
export default function Cart(){

    const {
      cart,
      calculateTotal,
      removeItemFromCart,
      decreaseQuantity,
      increaseQuantity,
      isCartVisible,
      toggleCartVisibility
    } = useCart();

    const cartRef = useRef(null);

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          isCartVisible === false &&
          cartRef.current &&
          !cartRef.current.contains(event.target)
        ) {
          toggleCartVisibility();
        }
      }

      if (isCartVisible === false) {
        document.addEventListener("click", handleClickOutside);
      }

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [isCartVisible, toggleCartVisibility]);

    return (
      <div
        className="cartContainer"
        id="cartContainer"
        style={{ right: isCartVisible ? "-500%" : "0", zIndex: 100000 }}
        ref={cartRef}
      >
        <i
          onClick={() => toggleCartVisibility()}
          style={{
            zIndex: "10010",
            position: "absolute",
            top: "2rem",
            right: isCartVisible ? "-500%" : "2rem",
            transition: ".3s ease-in-out",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            style={{ fill: "rgba(0, 0, 0, 1)", transform: "msFilter" }}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </i>
        <div className="cartTop">
          <svg
            width="30px"
            height="30px"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="#003470"
            className="bi bi-cart2"
          >
            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
          </svg>
          <strong>Carrito</strong>
        </div>
        {cart.length >= 1 ? (
          <div className="cartMiddle">
            {cart.map((cart) => (
              <article className="cart__card" key={cart.id}>
                <div className="cart__box">
                  <img src={cart.image} alt={cart.alt} className="cart__img" />
                </div>
                <div className="cart__details">
                  <h3 className="cart__title">
                    {cart.productName}{" "}
                    <span className="cart__price">
                      ${cart.price.toLocaleString("es-CO")}
                    </span>
                  </h3>

                  <div className="cart__amount">
                    <div className="cart__amount-content">
                      <span
                        className="cart__amount-box"
                        data-id="1"
                        onClick={() => decreaseQuantity(cart.id)}
                      >
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="12"
                            viewBox="0 0 24 24"
                            style={{
                              fill:
                                cart.quantity === 1
                                  ? "rgba(0, 0, 0, 0.118)"
                                  : "black",
                              transform: "msFilter:",
                            }}
                          >
                            <path d="M5 11h14v2H5z"></path>
                          </svg>
                        </i>
                      </span>

                      <span className="cart__amount-number">
                        {cart.quantity}
                      </span>

                      <span
                        className="cart__amount-box "
                        data-id="1"
                        onClick={() => increaseQuantity(cart.id)}
                      >
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="12"
                            viewBox="0 0 24 24"
                            style={{
                              fill: "black",
                              transform: "msFilter:",
                            }}
                          >
                            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                          </svg>
                        </i>
                      </span>
                    </div>

                    <i
                      className="cart__amount-trash deleteToCart"
                      data-id="1"
                      onClick={() => removeItemFromCart(cart.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        style={{
                          fill: "rgba(0, 0, 0, 1)",
                          transform: "msFilter",
                        }}
                      >
                        <path d="M15 2H9c-1.103 0-2 .897-2 2v2H3v2h2v12c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V8h2V6h-4V4c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm8 16H7V8h10v12z"></path>
                      </svg>
                    </i>
                  </div>

                  <span className="cart__subtotal">
                    <span className="cart__subtotal-price">
                      ${(cart.quantity * cart.price).toLocaleString("es-CO")}
                    </span>
                  </span>
                </div>
              </article>
            ))}
          </div>
        ) : (
          <div className="cartEmpty">
            <span style={{ fontWeight: "500", fontSize: "1.5rem" }}>
              Tu carrito está vacío
            </span>
            <img
              alt=""
              style={{ width: "200px", height: "100px" }}
              src="./cartempty.svg"
              loading="lazy"
            />
          </div>
        )}
        {cart.length >= 1 ? (
          <div className="cartBottom">
            {(cart.some((item) => item.price >= 37900) &&
              calculateTotal() >= 60000 &&
              cart.length > 1) ||
            (cart.length > 0 &&
              cart[0].quantity > 1 &&
              cart[0].price > 37900) ? (
              <span style={{ color: "green" }}>¡Descuento aplicado!</span>
            ) : (
              <></>
            )}
            <div className="cartQuantity">
              <span className="cartProducts">
                {cart.length} {cart.length === 1 ? "Producto" : "Productos"}
              </span>
              <span className="cartTotal">
                $
                {(
                  calculateTotal() -
                  ((cart.some((item) => item.price >= 37900) &&
                    calculateTotal() >= 60000 &&
                    cart.length > 1) ||
                  (cart.length > 0 &&
                    cart[0].quantity > 1 &&
                    cart[0].price > 37900)
                    ? 10000 - -calculateTotal() * 0.1
                    : 0)
                ).toLocaleString("es-CO")}
              </span>
            </div>
            {(cart.some((item) => item.price >= 37900) &&
              calculateTotal() >= 60000 &&
              cart.length > 1) ||
            (cart.length > 0 &&
              cart[0].quantity > 1 &&
              cart[0].price > 37900) ? (
              <span className="cartQuantity" style={{ fontSize: "1.3rem" }}>
                Estás ahorrando{" "}
                <span style={{ color: "green", fontWeight: "500" }}>
                  ${(calculateTotal() * 0.1 + 10000).toLocaleString("es-CO")}
                </span>
              </span>
            ) : (
              <span style={{ color: "rgba(0, 0, 0, 0.559)" }}>
                A partir de dos unidades accede a un descuento del 10% +
                $10.000. Válido para productos superiores a $37.900 y carritos
                desde $60.000.
              </span>
            )}
            <div className="cartButtons">
              <Link
                className="productButton"
                to="/checkout"
                onClick={() => toggleCartVisibility()}
              >
                Continuar compra
              </Link>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
}