import { Splide, SplideSlide } from "@splidejs/react-splide";

export default function FooterTop() {
  return (
    <div className="wrapperSlideFooter">
      <Splide
        options={{
          perPage: 1,
          height: "10rem",
          rewind: true,
          gap: "1rem",
          width: "22em",
          arrows: false,
          pagination: false
        }}
        aria-labelledby="basic-example-heading"
      >
        <SplideSlide>
          <div className="Shipping">
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Medios de pago</div>
                <div className="footerCardDetails">
                  Todas las tarjetas, PSE, efectivo,<br />
                  contra entrega, Addi y Sistecrédito
                </div>
              </div>

              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100px"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve"
              >
                <image
                  id="image0"
                  width="100"
                  height="100"
                  href="./card.webp"
                ></image>
              </svg>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="payment">
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Entregas veloces</div>
                <div className="footerCardDetails">
                  En cuestión de horas entregamos <br />
                  tu pedido a la repartidora
                </div>
              </div>

              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100px"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve"
              >
                <image
                  id="image0"
                  width="100"
                  height="100"
                  href="./envio2.webp"
                ></image>
              </svg>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="refund">
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Recibelo como regalo</div>
                <div className="footerCardDetails">
                  ¡Todos tus pedidos pueden ir
                  <br />
                  empacados como regalo!
                </div>
              </div>

              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100px"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve"
              >
                <image
                  id="image0"
                  width="80"
                  height="100"
                  href="./caja-de-regalo1.webp"
                ></image>
              </svg>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="whatsInfo">
            <div>
              <div className="footerCard">
                <div className="footerCardHeading">Soporte permanente</div>
                <div className="footerCardDetails">
                  Atención personalizada por
                  <br />
                  WhatsApp o correo
                </div>
              </div>

              <svg
                className="footerCardImg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="50"
                height="100px"
                viewBox="0 0 100 100"
                xmlSpace="preserve"
              >
                <image
                  id="image0"
                  width="80"
                  height="100"
                  href="./wasa2.webp"
                ></image>
              </svg>
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
}
